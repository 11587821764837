export function isValidCodiceFiscale(codiceFiscale) {
  if (!codiceFiscale) return false;
  codiceFiscale = codiceFiscale.toUpperCase();
  var pattern = /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/;
  if (pattern.test(codiceFiscale)) {
    return true;
  } else {
    return false;
  }
}

export function isValidPartitaIVA(partitaIVA) {
  if (!partitaIVA) return false;
  var pattern = /^[0-9]{11}$/;
  if (pattern.test(partitaIVA)) {
    return true;
  } else {
    return false;
  }
}
